import { Observable, Subject, distinctUntilChanged, filter, fromEvent, map, mergeWith, startWith } from 'rxjs';

export interface Storage<T> {
  /**
   * Observable that emits when the stored value changes, and at the start (with
   * the previously-stored value).
   */
  readonly watch$: Observable<T>;

  /** Get the currently-stored value. */
  read(): T;

  /** Overwrite with a new value. */
  write(value: T): void;
}

/**
 * Store things in local storage, and react to changes.
 */
export class StringStorage implements Storage<string | null> {
  private readonly changed = new Subject<void>();

  readonly watch$ = fromEvent<StorageEvent>(window, 'storage')
    .pipe(
      // Changes made in other tabs
      filter(event => event.storageArea === window.localStorage && event.key === this.key),
      // Changes made by this class
      mergeWith(this.changed),
      // Value stored in an earlier session
      startWith(null),
    )
    .pipe(
      map(() => this.read()),
      distinctUntilChanged(),
    );

  constructor(readonly key: string) {}

  read() {
    return window.localStorage.getItem(this.key);
  }

  write(value: string | null) {
    if (value != null) window.localStorage.setItem(this.key, value);
    else window.localStorage.removeItem(this.key);
    this.changed.next();
  }
}
