// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
})
export class RecommendationComponent {
  @Input()
  description = 'Here is a default description';

  @Input()
  class?: string;
}
