// Copyright (C) 2024 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { ERiskRating } from 'src/app/company-record/company-record.model';
import { getEnumKeyFromValue } from 'src/app/shared/util';

// This is the way enums should be done in Type Script
export enum ActionStatus {
  TAKE_ACTION = 'Take Action',
  MONITOR = 'Monitor',
  NO_IMMEDIATE_ACTION = 'No Immediate Action',
}

export enum SaqStatus {
  SUBMITTED = 'Submitted',
  NO_RESPONSE = 'No Response',
  IN_PROGRESS = 'In Progress',
  REQUESTED = 'Requested',
}

export enum MitigationLabel {
  BASIC = 'Basic',
  DEVELOPING = 'Developing',
  ADVANCED = 'Advanced',
}

export enum ImprovementOpportunity {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}

export function getSaqStatusDisplay(status: string) {
  switch (status) {
    case 'SUBMITTED':
      return SaqStatus.SUBMITTED;
    case 'NO_RESPONSE':
      return SaqStatus.NO_RESPONSE;
    case 'IN_PROGRESS':
      return SaqStatus.IN_PROGRESS;
    case 'REQUESTED':
      return SaqStatus.REQUESTED;
  }
}

export function getActionStatusDisplay(status: ActionStatus) {
  switch (status) {
    case ActionStatus.TAKE_ACTION:
      return 'Take Action';
    case ActionStatus.MONITOR:
      return 'Monitor';
    case ActionStatus.NO_IMMEDIATE_ACTION:
      return 'No Immediate Action';
  }
}

export function getActionStatusColour(status: ActionStatus) {
  switch (status) {
    case ActionStatus.TAKE_ACTION:
      return 'var(--fs-red)';
    case ActionStatus.MONITOR:
      return 'var(--fs-orange)';
    case ActionStatus.NO_IMMEDIATE_ACTION:
      return 'var(--fs-yellow)';
  }
}

export function getMitigationColour(status: string) {
  switch (status) {
    case MitigationLabel.BASIC:
      return 'var(--fs-red)';
    case MitigationLabel.DEVELOPING:
      return 'var(--fs-orange)';
    case MitigationLabel.ADVANCED:
      return 'var(--fs-green)';
  }
}

// TODO: Still need this because we don't have the label for the indicators
// Should add the labels to indicators in backend so we can use "getProgressColour()"
export function getMitigationColourOnScore(value: number | undefined | null) {
  // Check for null too as the backend web-api possibly can return it explicitly. Our current types declaration may not be robust enough yet to indicate this.
  if (value === null || value === undefined) {
    return null;
  }
  if (value < 30) {
    return 'var(--fs-red)';
  }
  if (30 <= value && value <= 70) {
    return 'var(--fs-orange)';
  }
  if (70 < value) {
    return 'var(--fs-light-green)';
  }
}

export function getLabelFromMitigationScore(score: number) {
  if (score === null) {
    return null;
  }
  if (score < 30) {
    return MitigationLabel.BASIC;
  }
  if (30 <= score && score <= 70) {
    return MitigationLabel.DEVELOPING;
  }
  if (70 < score) {
    return MitigationLabel.ADVANCED;
  }
}

export function getImprovementOpportunityFromMitigationScore(score: number) {
  if (score === null) {
    return null;
  }
  if (score < 30) {
    return ImprovementOpportunity.HIGH;
  }
  if (30 <= score && score <= 70) {
    return ImprovementOpportunity.MEDIUM;
  }
  if (70 < score) {
    return ImprovementOpportunity.LOW;
  }
}

export function getActionStatus(risk: keyof typeof ERiskRating, mitigationScore: number) {
  if (risk == null || mitigationScore == null) {
    return null;
  }
  if (
    (mitigationScore <= 70 &&
      (risk === getEnumKeyFromValue(ERiskRating, ERiskRating.HIGH) ||
        risk === getEnumKeyFromValue(ERiskRating, ERiskRating.MODERATE_HIGH))) ||
    (mitigationScore < 30 && risk === getEnumKeyFromValue(ERiskRating, ERiskRating.MODERATE))
  ) {
    return ActionStatus.TAKE_ACTION;
  }

  if (
    (mitigationScore >= 30 && risk === getEnumKeyFromValue(ERiskRating, ERiskRating.LOW)) ||
    (mitigationScore > 70 && risk === getEnumKeyFromValue(ERiskRating, ERiskRating.MODERATE_LOW))
  ) {
    return ActionStatus.NO_IMMEDIATE_ACTION;
  }
  return ActionStatus.MONITOR;
}

const sortOrder = {
  [ActionStatus.NO_IMMEDIATE_ACTION]: 1,
  [ActionStatus.MONITOR]: 2,
  [ActionStatus.TAKE_ACTION]: 3,
};

export function getActionStatusOrder(): string[] {
  function sortKeysByDescendingValues(obj: { [key: string]: number }): string[] {
    return Object.keys(obj).sort((a, b) => obj[b] - obj[a]);
  }
  return sortKeysByDescendingValues(sortOrder);
}

export const actionStatusSort = (a: ActionStatus, b: ActionStatus) => (a ? sortOrder[a] : 0) - (b ? sortOrder[b] : 0);
