// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';

import { BigTabsModule } from '../fs-next/big-tabs/big-tabs.module';
import { DataboxComponent } from '../fs-next/databox/databox.component';
import { PillTabsComponent } from '../fs-next/pill-tabs/pill-tabs.component';
import { SquishyButtonComponent } from '../fs-next/squishy-button/squishy-button.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { CollapsablePanelComponent } from './collapsable-panel/collapsable-panel.component';
import { EmptyComponent } from './empty/empty.component';
import { FileUploadControlComponent } from './file-upload-control/file-upload-control.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FooterComponent } from './footer/footer.component';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { IndicatorTagComponent } from './indicator-tag/indicator-tag.component';
import { LoadingComponent } from './loading/loading.component';
import { MaterialModule } from './material.module';
import { MitigationScoreIndicatorComponent } from './mitigation-score-indicator/mitigation-score-indicator.component';
import { ModalComponent } from './modal/modal.component';
import { MultipleOptionsComponent } from './multiple-options/multiple-options.component';
import { NavDotMenuComponent } from './nav-dot-tab-strip/nav-dot-menu/nav-dot-menu.component';
import { NavDotTabStripComponent } from './nav-dot-tab-strip/nav-dot-tab-strip.component';
import { NavDotTabComponent } from './nav-dot-tab-strip/nav-dot-tab/nav-dot-tab.component';
import { NoClientComponent } from './no-client/no-client.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { QuestionnaireRequestComponent } from './questionnaire-request/questionnaire-request.component';
import { RecommendationComponent } from './recommendation/recommendation.component';
import { RiskIndicatorComponent } from './risk-indicator/risk-indicator.component';
import { IndustryDescriptionTooltipDirective } from './sector-list/industry-description-tooltip.directive';
import { SectorListComponent } from './sector-list/sector-list.component';
import { TabStripAnchorScrollingDirective } from './tab-strip/tab-strip-anchor-scrolling.directive';
import { TabStripComponent } from './tab-strip/tab-strip-component';
import { TabStripHeaderComponent } from './tab-strip/tab-strip-header/tab-strip-header.component';
import { TabComponent } from './tab-strip/tab/tab-component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { TypeWriterInputDirective } from './typewriter-directive/typewriter-input.directive';

@NgModule({
  declarations: [
    EmptyComponent,
    NotFoundComponent,
    NoClientComponent,
    FooterComponent,
    BreadCrumbComponent,
    FileUploadComponent,
    CollapsablePanelComponent,
    ToggleSwitchComponent,
    AdminUsersComponent,
    ModalComponent,
    HelpModalComponent,
    SectorListComponent,
    IndustryDescriptionTooltipDirective,
    RecommendationComponent,
    QuestionnaireRequestComponent,
    TabStripComponent,
    TabComponent,
    TabStripHeaderComponent,
    IndustryDescriptionTooltipDirective,
    TypeWriterInputDirective,
    LoadingComponent,
    TabStripAnchorScrollingDirective,
    RiskIndicatorComponent,
    FileUploadControlComponent,
    AutocompleteComponent,
    NavDotTabComponent,
    NavDotTabStripComponent,
    NavDotMenuComponent,
    MultipleOptionsComponent,
    IndicatorTagComponent,
    MitigationScoreIndicatorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    DataTablesModule,
    MaterialModule,
    PillTabsComponent,
    BigTabsModule,
    DataboxComponent,
    SquishyButtonComponent,
  ],
  exports: [
    CollapsablePanelComponent,
    FooterComponent,
    BreadCrumbComponent,
    FileUploadComponent,
    ToggleSwitchComponent,
    AdminUsersComponent,
    ModalComponent,
    HelpModalComponent,
    SectorListComponent,
    MatFormFieldModule,
    TabStripComponent,
    TabComponent,
    TabStripHeaderComponent,
    IndustryDescriptionTooltipDirective,
    LoadingComponent,
    IndustryDescriptionTooltipDirective,
    MaterialModule,
    RecommendationComponent,
    QuestionnaireRequestComponent,
    IndustryDescriptionTooltipDirective,
    ReactiveFormsModule,
    TabStripAnchorScrollingDirective,
    TypeWriterInputDirective,
    RiskIndicatorComponent,
    FileUploadControlComponent,
    AutocompleteComponent,
    NavDotTabComponent,
    NavDotTabStripComponent,
    NavDotMenuComponent,
    MultipleOptionsComponent,
    SquishyButtonComponent,
    BigTabsModule,
    DataboxComponent,
    PillTabsComponent,
    IndicatorTagComponent,
    MitigationScoreIndicatorComponent,
  ],
})
export class UiModule {}
