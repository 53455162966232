{
  "name": "fair-supply-analytics",
  "version": "4.4.4",
  "scripts": {
    "ng": "ng",
    "start": "node serve",
    "start-http": "ng serve",
    "build": "ng build",
    "test": "TZ=Australia/Sydney ng test --watch=false",
    "lint": "ng lint",
    "typecheck": "tsc && tsc-strict",
    "formatcheck": "prettier . --check --no-editorconfig --config ../.prettierrc",
    "formatwrite": "prettier . --write --no-editorconfig --config ../.prettierrc",
    "prepare": "cd .. && husky client/.husky"
  },
  "private": true,
  "dependencies": {
    "@analytics/google-analytics": "^1.0.5",
    "@analytics/google-tag-manager": "^0.5.3",
    "@analytics/segment": "^1.1.4",
    "@angular/animations": "^17.3.1",
    "@angular/cdk": "^17.3.1",
    "@angular/common": "^17.3.1",
    "@angular/compiler": "^17.3.1",
    "@angular/core": "^17.3.1",
    "@angular/forms": "^17.3.1",
    "@angular/material": "^17.3.1",
    "@angular/platform-browser": "^17.3.1",
    "@angular/platform-browser-dynamic": "^17.3.1",
    "@angular/router": "^17.3.1",
    "@auth0/auth0-spa-js": "^2.0.2",
    "ag-grid-angular": "^31.0.3",
    "analytics": "^0.8.1",
    "angular-datatables": "^14.0.2",
    "angular-plotly.js": "^6.0.0",
    "bootstrap": "^4.6.2",
    "bootswatch": "^4.4.1",
    "d3": "^7.7.0",
    "d3-textwrap": "^3.0.0",
    "datatables.net": "^1.13.1",
    "datatables.net-bs4": "^1.13.1",
    "datatables.net-buttons": "^2.3.3",
    "datatables.net-buttons-dt": "^2.3.3",
    "fast-deep-equal": "^3.1.3",
    "file-saver": "^2.0.5",
    "jquery": "^3.6.2",
    "jszip": "^3.10.1",
    "launchdarkly-js-client-sdk": "^3.4.0",
    "ng-let": "^16.0.2",
    "ngx-bootstrap": "^12.0.0",
    "plotly.js-basic-dist-min": "^2.35.1",
    "proxy-polyfill": "^0.3.2",
    "rxjs": "^7.8.0",
    "safe-pipe": "^2.0.5-0",
    "save-svg-as-png": "^1.4.17",
    "topojson-client": "^3.1.0",
    "tslib": "^2.6.2",
    "zone.js": "^0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/architect": "^0.1703.1",
    "@angular-devkit/build-angular": "^17.3.1",
    "@angular-eslint/builder": "^17.3.0",
    "@angular-eslint/eslint-plugin": "^17.3.0",
    "@angular-eslint/eslint-plugin-template": "^17.3.0",
    "@angular-eslint/schematics": "^17.3.0",
    "@angular-eslint/template-parser": "^17.3.0",
    "@angular/cli": "^17.3.1",
    "@angular/compiler-cli": "^17.3.1",
    "@angular/language-service": "^17.3.1",
    "@types/bootstrap": "^4.6.1",
    "@types/d3": "^7.4.0",
    "@types/datatables.net": "~1.10.28",
    "@types/file-saver": "^2.0.5",
    "@types/jasmine": "~3.6.0",
    "@types/jquery": "^3.5.14",
    "@types/node": "^20.11.30",
    "@types/plotly.js": "^2.33.3",
    "@types/topojson-client": "^3.1.4",
    "@typescript-eslint/eslint-plugin": "^7.3.1",
    "@typescript-eslint/parser": "^7.3.1",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "^48.2.1",
    "eslint-plugin-prefer-arrow-functions": "^3.3.2",
    "husky": "^9.0.11",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "lint-staged": "^15.2.7",
    "prettier": "^3.3.2",
    "ts-node": "^10.9.2",
    "ts-xor": "^1.3.0",
    "typescript": "^5.4.3",
    "typescript-strict-plugin": "^2.4.3"
  },
  "lint-staged": {
    "*.{js,ts,tsx}": [
      "eslint --fix",
      "prettier --write"
    ],
    "*.{json,html,css,scss}": [
      "prettier --write"
    ]
  }
}
