import { Injectable, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, map, shareReplay } from 'rxjs';
import { deepEqual } from '../shared/util';
import { FeatureToggles, featureToggles as defaultToggles } from './feature-toggles';
import { ToggleStorage } from './toggle-storage';

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {
  private readonly storedToggles = new ToggleStorage('fs-feature-toggles');
  private readonly storedDefaults = new ToggleStorage('fs-feature-toggle-defaults');

  readonly toggles$ = this.storedToggles.watch$.pipe(distinctUntilChanged(deepEqual), shareReplay(1));

  private readonly _init = effect(() => {
    const changedDefaults = onlyChangedValues(this.storedDefaults.read(), defaultToggles);
    // Overwrite current toggles with values of those that have changed (to allow us to release features)
    this.storedToggles.merge(changedDefaults);
    // Store the new defaults so that next time the page loads, we know what needs to be overridden
    this.storedDefaults.write(defaultToggles);
  });

  /**
   * Observe a specific feature toggle.
   * @see {@link toggles$}
   */
  get$(key: keyof FeatureToggles) {
    return this.toggles$.pipe(
      map(toggles => toggles[key] ?? false),
      distinctUntilChanged(),
    );
  }

  /**
   * Set a specific feature toggle.
   * @see {@link store$}
   */
  set(key: keyof FeatureToggles, value: boolean) {
    this.storedToggles.merge({ [key]: value });
  }

  merge(toggles: Partial<FeatureToggles>) {
    this.storedToggles.merge(toggles);
  }
}

const onlyChangedValues = (previousDefaults: Partial<FeatureToggles>, currentDefaults: FeatureToggles) => {
  const changedDefaults: Partial<FeatureToggles> = {};
  for (const key of Object.keys(currentDefaults) as (keyof FeatureToggles)[]) {
    const prev = previousDefaults[key];
    const curr = currentDefaults[key];
    if (prev === curr) continue;
    changedDefaults[key] = !!curr;
  }
  return changedDefaults;
};

/**
 * Get a signal for a single feature toggle. Requires an injection context.
 *
 * @param key the feature toggle to watch
 * @return a read-only signal of the feature toggle's state
 * @see {@link featureToggle$} for an observable version
 */
export const featureToggle = (key: keyof FeatureToggles) => toSignal(featureToggle$(key), { requireSync: true });

/**
 * Get an observable for a single feature toggle. Requires an injection context.
 *
 * @param key the feature toggle to watch
 * @return an observable of the feature toggle's state
 * @see {@link featureToggle} for a signal version
 */
export const featureToggle$ = (key: keyof FeatureToggles) => inject(FeatureToggleService).get$(key);
