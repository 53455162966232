// @ts-strict-ignore
import { Component, HostBinding, Input } from '@angular/core';
import { ERiskRating, RiskRating } from 'src/app/company-record/company-record.model';

@Component({
  selector: 'app-indicator-tag',
  templateUrl: './indicator-tag.component.html',
  styleUrls: ['./indicator-tag.component.scss'],
})
export class IndicatorTagComponent {
  @Input() rating: ERiskRating;
  @Input() size: 'small' | 'large' = 'small';

  @HostBinding('class.app-tag-small') protected get clsSmall() {
    return this.size !== 'large';
  }
  @HostBinding('class.app-tag-large') protected get clsLarge() {
    return this.size === 'large';
  }
  @HostBinding('class.fsn-skeleton') protected get clsSkeleton() {
    return this.rating == null;
  }
  @HostBinding('style.border-color') protected get colorProperty() {
    const variant = this.rating == null ? 'low' : this.rating.toLowerCase().replace('_', '-');
    return `var(--app-indicator-color-${variant})`;
  }
  protected get text() {
    return RiskRating[this.rating] ?? '-';
  }
}
