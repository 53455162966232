// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, HostBinding, Input, booleanAttribute } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'fsn-squishy-button',
  templateUrl: './squishy-button.component.html',
  styleUrls: ['./squishy-button.component.scss'],
  standalone: true,
  imports: [MatIconModule],
})
export class SquishyButtonComponent {
  @Input() type: 'button' | 'submit' = 'button';
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';
  @Input() size: 'medium' | 'large' = 'large';

  @Input({ transform: booleanAttribute })
  disabled = false;
  @Input()
  state: 'normal' | 'doing' | 'done' = 'normal';

  @HostBinding('class')
  protected get cls() {
    return {
      'app-primary': this.color === 'primary',
      'app-accent': this.color === 'accent',
      'app-warn': this.color === 'warn',
      'app-disabled': this.disabled,
      'button-medium': this.size === 'medium',
      'button-large': this.size === 'large',
      'app-is-done': this.state === 'done',
      'app-is-doing': this.state === 'doing',
      'app-is-normal': this.state === 'normal',
    };
  }
}
