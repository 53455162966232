// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { SectorScope } from '../company-record/company-record-sectors/company-record-sector.model';
import { EntityService, HasId } from '../core/entity/entity.service';
import { Country } from '../country/country.model';
import { Industry } from '../industry/industry.model';
import { AlertService } from '../shared/alert.service';
import { UserService } from '../user/user.service';
import { Company } from './company.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends EntityService<Company> {
  constructor(
    protected userService: UserService,
    protected alertService: AlertService,
    protected httpClient: HttpClient,
  ) {
    super(alertService, httpClient);
  }

  /**
   * Get all companies with optional extra includes of company properties. Calls `super.getAll$`.
   */
  getAllIncludes$(includeCompanyIdentifiers = false): Observable<Company[]> {
    let params: { [param: string]: string | string[] } = {};

    if (includeCompanyIdentifiers) {
      params.includeCompanyIdentifiers = 'true';
    }

    params = Object.keys(params).length ? params : null;

    return super.getAll$(params);
  }

  lookup$(companyName: string, country: string) {
    const url = this.getWebApiEndpoint('lookup');

    const params = { companyName, country };

    this.validateParameters(params);

    const req$ = this.httpClient
      .get<MaybePaginated<ZoomInfoCompany>>(url, { params })
      .pipe(map(value => unpackPaginatedResponse(value)));

    return this.handleGetOrCreateResponse(
      req$,
      {
        log: `Lookup ${this.singularIdentifier()} failed`,
        nice: `Failed to lookup ${this.singularIdentifier()}`,
      },
      false,
    );
  }

  details$(companyId: number) {
    const url = this.getWebApiEndpoint('details');

    const params = { companyId };
    const req$ = this.httpClient.get<CompanyDetails>(url, { params });

    return this.handleGetOrCreateResponse(req$, {
      log: `Details ${this.singularIdentifier()} failed`,
      nice: `Failed to get details for ${this.singularIdentifier()}`,
    });
  }

  classify$(companyName: string, country: string): Observable<CompanyClassification> {
    const url = this.getWebApiEndpoint('retrieve-classification');

    const params = { companyName, country };

    this.validateParameters(params);

    const req$ = this.httpClient.post<CompanyClassification>(url, params);

    return this.handleGetOrCreateResponse(req$, {
      log: `Classify ${this.singularIdentifier()} failed`,
      nice: `Failed to classify ${this.singularIdentifier()}`,
    });
  }

  pluralIdentifier(): string {
    return 'companies';
  }

  singularIdentifier(): string {
    return 'company';
  }
}

export interface CompanySector extends HasId {
  country: Country;
  industry: Industry;
  weight: number;
}

export interface CompanySearch {
  name: string;
  country: Country;
  id: number;
  type: CompanySearchType;
}

export enum CompanySearchType {
  BASIC = 'BASIC',
  RECORD = 'RECORD',
  ZOOMINFO = 'ZOOMINFO',
}

export interface ZoomInfoCompany {
  id: number;
  name: string;
}

export interface CompanyDetails {
  id: number;
  city: string;
  country: string;
  descriptions: string[];
  foundedYear: string;
  name: string;
  parentName: string;
  ultimateParentName: string;
  revenue: string;
  state: string;
  website: string;

  /**
   * The initial name of the company as entered by the user, during company search.
   */
  _name: string;
}

export interface CompanyClassification {
  id: number;
  name: string;
  country: string;
  sectors: { uri: string; weight: number }[];
  rationale: string;
  description: string;
  website: string;
  scope: keyof typeof SectorScope;
}

type Paginated<T> = {
  currentPage: number;
  maxResults: number;
  totalResults: number;
  data: T[];
};
type MaybePaginated<T> = T[] | Paginated<T>;

const unpackPaginatedResponse = <T>(value: MaybePaginated<T>): T[] => ('currentPage' in value ? value.data : value);
