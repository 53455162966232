<!-- 
  Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
  Unauthorized copying of this file, via any medium is strictly prohibited.
  Proprietary and confidential. 
-->

<div class="card no-shadow{{ ' ' + class }}">
  <section class="card-body">
    <header class="d-flex align-items-centre flex-wrap gap-2 mb-2">
      <h3>
        <mat-icon fontSet="material-icons-outlined">auto_awesome</mat-icon>
        Recommendation
      </h3>
      <section class="ml-auto">
        <ng-content select=".header-right" />
      </section>
    </header>

    <span class="d-block">{{ description }}</span>
  </section>
  <section class="card-footer">
    <ng-content select=".content" />
  </section>
</div>
