<!--
  Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
  Unauthorized copying of this file, via any medium is strictly prohibited.
  Proprietary and confidential.
-->

<div class="block mt-4 table-responsive">
  <table datatable *ngIf="dtOptions" [dtOptions]="dtOptions" class="table table-hover table-striped table-borderless">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">User Name</th>
        <th scope="col">Email</th>
        <th *ngIf="allowRemove" scope="col" data-sortable="false" width="100px"></th>
      </tr>
    </thead>
  </table>
</div>

<!-- Templates-->
<ng-template #userDetailsCell let-data="adtData">
  <div>
    <a *ngIf="!selectedRouterLinkPrefix" class="grow" (click)="select(data)" role="button">
      <div class="media">
        <img class="align-self-center mr-3" [src]="data.picture" referrerpolicy="no-referrer" />
        <div class="media-body align-self-center">{{ data.firstName }} {{ data.lastName }}</div>
      </div>
    </a>
    <a *ngIf="selectedRouterLinkPrefix" class="grow" [routerLink]="[selectedRouterLinkPrefix, data.id]">
      <div class="media">
        <img class="align-self-center mr-3" [src]="data.picture" referrerpolicy="no-referrer" />
        <div class="media-body align-self-center">{{ data.firstName }} {{ data.lastName }}</div>
      </div>
    </a>
  </div>
</ng-template>
<ng-template #deleteButtonCell let-data="adtData">
  <button class="btn btn-danger btn-sm" (click)="delete(data)">Remove</button>
</ng-template>
<!-- Modals -->
<div class="modal fade" id="removeModal" tabindex="-1" role="dialog" aria-labelledby="removeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content block">
      <h5>Are you sure?</h5>
      <div class="mt-4">
        {{ confirmDeleteMessage }}
      </div>
      <div class="float-right">
        <button class="btn btn-danger" (click)="doDelete()">Yes</button>
        <button type="button" class="btn btn-link mr-3" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>
