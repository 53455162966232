import { Observable, map } from 'rxjs';
import { FeatureToggles, featureToggles } from './feature-toggles';
import { Storage, StringStorage } from './storage';

/**
 * Store feature toggles in local storage.
 */
export class ToggleStorage implements Storage<Partial<FeatureToggles>> {
  private readonly stringStorage: Storage<string | null>;
  readonly watch$: Observable<Partial<FeatureToggles>>;

  constructor(readonly key: string) {
    this.stringStorage = new StringStorage(key);
    this.watch$ = this.stringStorage.watch$.pipe(map(parseToggles));
  }

  read() {
    return parseToggles(this.stringStorage.read());
  }

  write(value: Partial<FeatureToggles>) {
    this.stringStorage.write(JSON.stringify(value));
  }

  /** Merge a partial value with the currently-stored value. */
  merge(value: Partial<FeatureToggles>) {
    this.write({ ...this.read(), ...value });
  }
}

export const parseToggles = (json: string | null): Partial<FeatureToggles> => {
  if (!json) return {};
  const value = JSON.parse(json);
  if (value == null || typeof value !== 'object') {
    throw new Error('Value is not an object');
  }
  // Make sure the object only contains items that are currently-known feature toggles.
  return Object.keys(featureToggles)
    .filter(k => k in value)
    .reduce((acc, key) => ({ ...acc, [key]: !!value[key] }), {});
};
